<template>
    <v-app class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row">
                <div class="col-6">
                  <h4>
                    Parents meet settings
                  </h4>
                  <router-link :to="{ name: 'dashboard' }">
                    Dashboard
                  </router-link>
                  \
                  Parents meet settings
                </div>
                  <div class="col-12">
                      <v-tabs class="justify-start" v-model="tab" vertical>
                        <v-tabs-slider color="yellow"></v-tabs-slider>
                        <v-tab class="justify-start"> Invitation letter setting</v-tab>
                        <v-tab-item>
                          <invitation-letter-setting 
                            ></invitation-letter-setting>
                        </v-tab-item>
                      </v-tabs>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app>
  </template>

  <script>
  import InvitationLetterSetting from "@/view/pages/setting/parents-meet/InvitationLetterSetting";
  
  export default {
  
      components:{
        InvitationLetterSetting
      },
      data(){
          return{
            tab:0,
          }
      },
      methods:{
          
      },
      mounted() {
        
      }
  }
  </script>