<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div v-if="options.length > 0">
                            <v-col md="12" v-for="(option,value) in options" :key="value" >
                                <!--If String but colorpicker-->
                                <div v-if="option.name=='PARENTS_MEET_TITLE_FONT_COLOR' ||
                                    option.name=='PARENTS_MEET_SUB_TITLE_BACKGROUND_COLOR'||
                                    option.name=='PARENTS_MEET_SUB_TITLE_FONT_COLOR' ||
                                    option.name=='PARENTS_MEET_BODY_BACKGROUND_COLOR' ||
                                    option.name=='PARENTS_MEET_BODY_FONT_COLOR' ||
                                    option.name=='PARENTS_MEET_TABLE_TH_BACKGROUND_COLOR' ||
                                    option.name=='PARENTS_MEET_TABLE_TH_FONT_COLOR' ||
                                    option.name=='PARENTS_MEET_TABLE_TD_BACKGROUND_COLOR' ||
                                    option.name=='PARENTS_MEET_TABLE_TD_FONT_COLOR' ||
                                    option.name=='PARENTS_MEET_FORM_VALUE_LINE_COLOR'
                                ">
                                    <div class="d-flex">
                                        <a href="javascript:;" @click="viewComponentImage(option.name)">
                                            <i class="fa fa-info"></i>
                                        </a>
                                        <label class="ml-4" for>{{ option.option_name }}</label><br/>
                                    </div>

                                    <verte menuPosition="bottom"
                                           model="hex"
                                           v-model="option.value"
                                    ></verte>
                                </div>
                                <div v-else>
                                    <!--If file-->
                                    <div class="d-flex">
                                        <a href="#" v-if="option.data_type == 'file' " @click="viewComponentImage(option.name)">
                                            <i class="fa fa-info"></i>
                                        </a>
                                        <v-file-input
                                            v-if="option.data_type=='file'"
                                            dense
                                            class="ml-4"
                                            outlined
                                            :label="option.option_name"
                                            v-model="option.value"
                                            prepend-icon=""
                                            prepend-inner-icon="mdi-paperclip"
                                        ></v-file-input>
                                    </div>

                                    <img v-if="option && option.option_image_path && option.option_image_path['real']" :src="option.option_image_path['real']" class="mt-3 ml-3 border rounded" style="width:100px;height:100px;object-fit:contain;" alt="">
                                    <!--If String-->
                                    <v-text-field v-if="option.data_type=='string'" dense outlined :label="option.option_name" v-model="option.value" >
                                    </v-text-field>
                                    <span class="text-warning" v-if="option.data_type=='string' && option.name=='ONLINE_ADMISSION_ADMIT_CARD_SUB_TITLE'">
                                        <small>
                                            <strong>You can use program  or level with  for dynamic content.</strong>
                                        </small>
                                    </span>
                                    <!--If Boolean-->
                                    <v-switch v-if="option.data_type =='boolean'" v-model="option.value"
                                              :label="option.value ? `Enabled ${option.option_name}`:`Disabled ${option.option_name}`">
                                    </v-switch>
                                    <!--If Text-->
                                    <div v-if="option.data_type =='text'">
                                        <label for>{{ option.option_name }}</label><br/>
                                        <ckeditor   :config="editorConfig" v-model="option.value"></ckeditor>
                                    </div>

                                </div>


                            </v-col>
                        </div>
                        <div v-show="options.length == 0">
                            This information is not working.
                        </div>
                        <div class="col-md-12 float-right"  v-if="options.length > 0">
                            <v-btn :loading="loading"
                                    @click="saveSetting"
                                    class="text-white float-right btn btn-primary"
                                    depressed>Save settings
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pdf-component-info
            ref="view-component-image"
        ></pdf-component-info>
    </div>
</template>
<script>
import OptionService from "@/core/services/option/OptionService";
import PdfComponentInfo from "./PdfComponentInfo.vue";

const option=new OptionService();

export default {
    components:{
        PdfComponentInfo
    },
    data(){
        return{
            loading:false,
            options:[],
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList']]
            },
        }
    },
    methods:{
        optionNeedToBeRendered(){
          let data= [
              'PARENTS_MEET_TITLE',
              'PARENTS_MEET_HEADER_BACKGROUND_COLOR',
              'PARENTS_MEET_TITLE_FONT_COLOR',
              'PARENTS_MEET_HEADER_LOGO',
              'PARENTS_MEET_TITLE',
              'PARENTS_MEET_SUB_TITLE',
              'PARENTS_MEET_SUB_TITLE_BACKGROUND_COLOR',
              'PARENTS_MEET_SUB_TITLE_FONT_COLOR',
              'PARENTS_MEET_BODY_BACKGROUND_COLOR',
              'PARENTS_MEET_BODY_FONT_COLOR',
              'PARENTS_MEET_LETTER_TEXT',
              'PARENTS_MEET_TABLE_TH_BACKGROUND_COLOR',
              'PARENTS_MEET_TABLE_TH_FONT_COLOR',
              'PARENTS_MEET_TABLE_TD_BACKGROUND_COLOR',
              'PARENTS_MEET_TABLE_TD_FONT_COLOR',
              'PARENTS_MEET_FORM_VALUE_LINE_COLOR'
            ];
          return data;
        },
        getOptions(){
            let data={};
            data.name=this.optionNeedToBeRendered();
            option
                .getByKeys(data)
                .then(response => {
                    if(response.data){
                        this.options=response.data.options;

                    }
                })
                .catch((err) => {

                })
                .finally(() => {

                });
        },
        createFormData(){
            let  formData = new FormData();
            for (const option of this.options) {
                if (option.data_type === 'file' && option.value instanceof File) {
                    formData.append(option.name, option.value);
                } else {
                    formData.append(option.name, option.value);
                }
            }
            return formData;
        },
        saveSetting(){
            let fd= this.createFormData();
            this.loading=true;
            option
                .updateOptionByKey(fd)
                .then(response => {
                    this.getOptions();
                    this.$snotify.success("Information updated");
                })
                .catch(error => { })
                .finally(() => {
                    this.loading=false;
                });
        },
        viewComponentImage(name){
            this.$refs['view-component-image'].showModal("media/pdf-settings/parents-meet/", name);
        }
    },
    mounted() {
        this.getOptions();
    }
}
</script>
